html {
    height: 100%;
}

body {
    height: 100%;
}

#root {
    height: 100%;
}

.container {
    padding: 0 20px;
}

.error {
    background-color: #B00020 !important;
}

.center {
    text-align: center;
}

.right {
    float: right;
}

.login {
    height: 100%;
    padding-top: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.account-group {
    margin-bottom: 1em;
}

.link {
    color: white
}

.link:hover {
    color: #BFBFBF
}
